function getSearchParameters() {
    var prmstr = window.location.search.substr(1);
    return prmstr != null && prmstr != "" ? transformToAssocArray(prmstr) : {};
}

function transformToAssocArray( prmstr ) {
    var params = {};
    var prmarr = prmstr.split("&");
    for ( var i = 0; i < prmarr.length; i++) {
        var tmparr = prmarr[i].split("=");
        params[tmparr[0]] = tmparr[1];
    }
    return params;
}

var urlParams = '';
var refreshTimer = false;

function refreshWithNewParams()
{
    top.location.href = window.location.href.split('?')[0]+'?'+jQuery.param(urlParams);
}

function getFixedSearchParameters()
{
    var urlParams = getSearchParameters();
    //clear urlParams
    $.each(urlParams, function(key, value){
        if(key == 'page') return;
        if(key == 'order') return;
        if(key == 'dir') return;
        if(key == 'perPage') return;
        delete urlParams[key];
    });
    //add every filter param to it
    $('.filter-select').each(function(index){
        if($(this).val() == "" || $(this).val() == null) return;
        urlParams[$(this).data('code')+$(this).data('type')] = $(this).val();
    });
    return urlParams;
}

$(document).ready( function () {
    urlParams = getFixedSearchParameters();

    //magnific popup
    $('.images-block').magnificPopup({
        delegate: 'a',
        type: 'image',
        gallery: {
            enabled: true 
        }
    });

    //header search
    $('.header-search-form').on('submit', function(event){
        event.preventDefault();
        $(this).children('small').hide();
        var keyword = $(this).find('.search-input').val();
        if(keyword.length < 3){
            $(this).children('small').show();
            return;
        }
        top.location.href = $(this).attr('action')+'/'+keyword;
    });

    //pagination perpage
    $('.perpage-select').on('change', function(){
        var perPage = $(this).val();
        urlParams["perPage"] = perPage;
        refreshWithNewParams();
    });

    //pagination order
    $('.order-select').on('change', function(){
        urlParams["order"] = $(this).children('option:selected').data('order');
        urlParams["dir"] = $(this).children('option:selected').data('dir');
        refreshWithNewParams();
    });

    //subcategory select
    $('.subcategory-select').on('change', function(){
        if($(this).val() == -1){
            return;
        }
        top.location.href = $(this).val();
    });

    //filter select
    $('.filter-select').on('change', function(){
        if(refreshTimer){
            clearTimeout(refreshTimer);
        }
        urlParams[$(this).data('code')+$(this).data('type')] = $(this).val();
        refreshTimer = setTimeout(function(){
            refreshWithNewParams();
        }, 2000);
    });

    //filter remove
    $('.filter-remove').on("click", function(){
        delete urlParams[$(this).data('code')];
        delete urlParams[$(this).data('code')+"_min"];
        delete urlParams[$(this).data('code')+"_max"];
        refreshWithNewParams();
    });

    //add to cart
    $('.btn-cart').click(function(){
        var qty = $('#input-quantity').val();
        if(typeof(qty) == "undefined"){
            qty = 1;
        }
        var that = $(this);
        $.ajax({
                url: baseUrl + "/cart/add/" + that.data('id') + '/' + qty,
                method: "GET"
            })
            .done(function(data) {
                $('.minicart').html(data);
                if(that.data('label')){
                    that.html('Hozzáadva <i class="fa fa-check"></i>');
                } else {
                    that.html('<i class="fa fa-check"></i>');
                }
            })
            .fail(function() {
                alert( "Hiba a kosárhoz adás közben! Kérem próbálja újra!" );
            });
    });

    //remove from cart
    $(document).on('click', '.btn-cart-remove', function(){
        var that = $(this);
        $.ajax({
                url: baseUrl + "/cart/remove/" + that.data('rowid'),
                method: "GET"
            })
            .done(function(data) {
                $('.minicart').html(data);
                if($('div.cart').length){
                    location.reload();
                }
            })
            .fail(function() {
                alert( "Hiba! Kérem próbálja újra!" );
            });
    });

    //update cart qty
    $(document).on('click', '.btn-cart-update', function(){
        var rowid = $(this).data('rowid');
        top.location.href = baseUrl + '/cart/update/'+rowid+'/'+$('#qty_'+rowid).val();
    });

    //open menu
    $('#cssmenu li.has-sub').each(function(){
        var actives = $('li.active');
        if($(this).find(actives).length){
            //console.log($(this));
            $(this).children('a').click();
        }
    });

    //contact form
    $('.contact-form').on('submit', function(e){
        $('.contact-form button').prop('disabled', true);
        event.preventDefault();
        $.ajax({
                url: baseUrl + "/sendmail",
                method: "POST",
                data: $(".contact-form").serialize()
            })
            .done(function(data) {
                $('.contact-form button').html('Elküldve <i class="fa fa-check"></i>');
                $('.contact-form').trigger('reset');

            })
            .fail(function() {
                alert( "Hiba! Kérem próbálja újra!" );
                $('.contact-form button').prop('disabled', false);
            });
    });
} );